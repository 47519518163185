import React, { useContext, useState, useEffect, useRef } from "react";
import AppContext from "../../../context/store";

import { Grid, Column } from "../../../theme/Grid";
import AuthContainer from "../../../components/AuthContainer";
import StatsCard from "../../../components/Cards/StatsCard";
import Table from "../../../theme/Table";
import SectionTitle from "../../../theme/SectionTitle";
import Axios from "axios";
import moment from "moment";
import Alert from "@material-ui/lab/Alert";
import UndefinedSenderAlert from "../../../components/Alerts/UndefinedSenderAlert";
import WpUndefinedAlert from "../../../components/Alerts/WpUndefinedAlert";
import RoundedRow from "../../../components/Table/RoundedRow";
import {
  CardMembership,
  Check,
  Close,
  SmsFailed,
  Timer,
  InfoOutlined,
  MailOutline,
  WhatsApp,
  CollectionsOutlined,
} from "@material-ui/icons";
import { toast } from "react-toastify";
import Button from "../../../theme/Button";
import { FormControlLabel, Switch, Typography } from "@material-ui/core";
import FCLabel from "../../../theme/FCLabel";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import LoaderEN from "../../../assets/images/circular.gif";
import LoaderSM from "../../../assets/images/loaderSM.gif";
import Loader from "../../../assets/images/loader.gif";
import useHandleParams from "../../../functions/HandleParams";

const SMSIndex = () => {
  const context = useContext(AppContext);
  const HandleParams = useHandleParams()

  const [tab, setTab] = useState(0);
  const [detailTab, setDetailTab] = useState(0);
  const [availableCredit, setAvailableCredit] = useState("");
  const [filterExpanded, setFilterExpanded] = useState(false);
  const [groupFilterExpanded, setGroupFilterExpanded] = useState(false);
  const [showGroupSMSDetail, setShowGroupSMSDetail] = useState(false);
  const [showGroupWPDetail, setShowGroupWPDetail] = useState(false);
  const [detailState, setDetailState] = useState(1);

  const [phone, setPhone] = useState(null);
  const [apiKey, setApiKey] = useState("");
  const [wpState, setWpState] = useState(null);
  const [wpPhone, setWPPhone] = useState(null);
  const [groupMessageDetailId, setGroupMessageDetailId] = useState("");
  const [ReportMessageBody, setReportMessageBody] = useState("");

  const [failedMessage, setFailedMessage] = useState(0);
  const [successMessage, setSuccessMessage] = useState(0);
  const [waitingMessage, setWaitingMessage] = useState(0);

  const [start, setStart] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );
  const [end, setEnd] = useState(moment().format("YYYY-MM-DD"));
  const [loaded, setLoaded] = useState(false);
  const [showSms, setShowSms] = useState(false);
  const [sms, setSms] = useState({ content: "", date: "" });
  const [debouncedValue, setDebouncedValue] = useState('')
  const urlPage = parseInt(
    new URLSearchParams(window.location.search).get("page")
  );
  const [pagination, setPagination] = useState({
    page: urlPage ? urlPage : 1,
    total_page: null,
    onChange: (page) => setPagination((prev) => ({ ...prev, page: page })),
  });

  const [automatedWPReports, setAutomatedWPReports] = useState({
    loaded: false,
    pagination: {
      page: 1,
      total_page: null,
      onChange: (page) => setAutomatedWPReports((prev) => ({ ...prev, pagination: { ...prev.pagination, page: page } })),
    },
    reports: [],
  });
  const [groupSMS, setGroupSMS] = useState({
    start: moment().subtract(30, "days").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    phone: "",

    loaded: false,
    showSms: false,
    sms: { content: "", date: "" },
    pagination: {
      page: 1,
      total_page: null,
      onChange: (page) => setGroupSMS((prev) => ({ ...prev, pagination: { ...prev.pagination, page: page } })),
    },

    reports: [],
  });
  const [groupWP, setGroupWP] = useState({
    start: moment().subtract(30, "days").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    phone: "",

    loaded: false,
    showSms: false,
    sms: { content: "", date: "" },
    pagination: {
      page: 1,
      total_page: null,
      onChange: (page) => setGroupWP((prev) => ({ ...prev, pagination: { ...prev.pagination, page: page } })),
    },

    reports: [],
  });
  // Grup gönderim sms detay
  const [singleGroupSMS, setSingleGroupSMS] = useState({
    start: moment().subtract(30, "days").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),

    loaded: false,
    showSms: false,
    sms: { content: "", date: "" },
    pagination: {
      page: 1,
      total_page: null,
      onChange: (page) => setSingleGroupSMS((prev) => ({ ...prev, pagination: { ...prev.pagination, page: page } })),
    },
    reports: [],
  });
  // grup gönderim WP tekli detay
  const [singleGroupWP, setSingleGroupWP] = useState({
    start: moment().subtract(30, "days").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),

    loaded: false,
    showSms: false,
    sms: { content: "", date: "" },
    pagination: {
      page: 1,
      total_page: null,
      onChange: (page) => setSingleGroupWP((prev) => ({ ...prev, pagination: { ...prev.pagination, page: page } })),
    },

    reports: [],
  });

  const [reports, setReports] = useState([]);
  const [groupSmsDetailModal, setGroupSmsDetailModal] = useState(false);
  const [groupSmsDetail, setGroupSmsDetail] = useState({
    phone: "",
    name: null,
    surname: null,
    message: null,
  });
  const [sender, setSender] = useState("");

  const removeFilter = () => {
    setFilterExpanded(false);
    setGroupFilterExpanded(false);
    setStart(moment().subtract(30, "days").format("YYYY-MM-DD"));
    setEnd(moment().format("YYYY-MM-DD"));
    setPhone(null);
  };

  const getContextSenderSettings = () => {
    Axios.get(`${context.api_endpoint}/company/itself`).then((response) => {
      const {
        data: { data },
      } = response;
      setSender(data.sender);
      context.dispatch({
        type: "SET_COMPANY_SMS_SETTINGS",
        payload: {
          company_sms_settings: { sender: data.sender },
          company_sms_settings_loaded: true,
        },
      });
    });
  };

  const getMessageSettings = () => {
    Axios.get(`${context.api_endpoint}/company/message/credit`).then(
      ({ data }) => {
        setAvailableCredit(`${data.data.credit} SMS`);
        context.dispatch({
          type: "SET_SMS_CREDIT",
          payload: { sms_credit: data.data.credit },
        });
      }
    );
  };

  const checkWp = async () => {
    await Axios.get(`${context.api_endpoint}/company/get/wp/message/settings`)
      .then(async (ress) => {
        if (ress.data.data.api_key !== "") {
          setApiKey(ress.data.data.api_key);
        }
        await Axios.post(
          `${context.api_endpoint}/salon/wp/login/check/active/${ress.data.data.device_key}`
        )
          .then((ress) => {
            if (ress.status === 200) {
              setWpState(ress.data.is_connect);
              setWPPhone(ress.data.data.device_number);
            }
          })
          .catch((error) => {
            if (error.response.status !== 200) {
              setWpState(false);
              setWPPhone(false);
            }
          });
      })
      .catch((err) => console.log(err));
  };

  const creditPayback = () => {
    Axios.post(`${context.api_endpoint}/company/message/credit/payback`)
      .then((response) => {
        if (response.status === 201) {
          toast.success(response.data.data);
          getMessageReports();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  // otomatik wp göndeirm raporları
  const getAutomatedWPReports = async () => {
    await Axios.get(
      `${context.api_endpoint}/company/message/reports`,
      {
        params: {
          ...(filterExpanded && {
            start: start,
            end: end,
            phone: phone ? phone : "",
          }),
          page: automatedWPReports.pagination.page ? automatedWPReports.pagination.page : 1,
          report_type: 2,
        },
      }
    )
      .then((response) => {
        if (response.status === 201) {
          const {
            data: { data },
          } = response;
          setAutomatedWPReports({
            loaded: true,
            reports: [
              ...data.records.map((item) => {
                return {
                  ...item,
                  date: moment(item.date).tz("Europe/Istanbul").format("LLL"),
                  state:
                    item.state === "1"
                      ? "DELIVERED"
                      : item.state === "2"
                        ? "UNDELIVERED"
                        : "",
                };
              }),
            ],
            pagination: {
              ...automatedWPReports.pagination,
              page: data.page,
              total_page: data.records.length,
            },
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warn(e.response.data.message);
        }
      });
  };

  // get name and surname for group sms detail
  const getCustomerName = async (phone) => {
    await Axios.get(
      `${context.api_endpoint}/company/customer/single/data?phone=${phone}`
    ).then(({ data }) => {
      setGroupSmsDetail({
        ...groupSmsDetail,
        name: data.data.name,
        surname: data.data.surname,
      });
    });
  };

  // otomatik sms gönderim raporları
  const getMessageReports = () => {
    Axios.get(
      `${context.api_endpoint}/company/message/reports`,
      {
        params: {
          ...(filterExpanded && {
            start: start,
            end: end,
            phone: phone ? phone : "",
          }),
          page: pagination.page ? pagination.page : 1,
          report_type: 1,
        },
      }
    )
      .then((response) => {
        if (response.status === 201) {
          const {
            data: { data },
          } = response;
          setLoaded(true);
          setReports([
            ...data.records.map((item) => {
              return {
                ...item,
                date: moment(item.date).tz("Europe/Istanbul").format("LLL"),
                state: item.has_paid === 1 ? "PAYBACK_COMPLETED" : item.state,
              };
            }),
          ]);
          setPagination({
            ...pagination,
            page: data.page,
            total_page: data.records.length,
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warn(e.response.data.message);
        }
      });
  };

  // Grup-tekli sms gönderimleri
  const getGroupMessageReports = () => {
    Axios.get(
      `${context.api_endpoint}/company/message/reports/group`,
      {
        params: {
          ...(groupFilterExpanded && {
            start: groupSMS.start,
            end: groupSMS.end,
            phone: groupSMS.phone ? groupSMS.phone : "",
          }),
          page: groupSMS.pagination.page ? groupSMS.pagination.page : 1,
          report_type: 1,
        },
      }
    )
      .then((response) => {
        if (response.status === 201) {
          const {
            data: { data },
          } = response;
          setGroupSMS({
            ...groupSMS,
            loaded: true,
            reports: [
              ...data.records.map((item) => {
                return {
                  ...item,
                  date: moment(item.send_time)
                    .tz("Europe/Istanbul")
                    .format("LLL"),
                  waiting: item.count - (item.fail + item.success),
                  beforeCredit: item.credit_before,
                };
              }),
            ],
            pagination: {
              ...groupSMS.pagination,
              //page: data.page,
              total_page: data.records.length,
            },
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warn(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    if (tab === 0 || tab === 1) {
      getGroupMessageReports()
    }
  }, [groupSMS.pagination.page, groupFilterExpanded, groupSMS.start, groupSMS.end, groupWP.end, groupWP.end])


  // Grup-tekli wp gönderimleri
  const getGroupWPMessageReports = () => {
    Axios.get(
      `${context.api_endpoint}/company/message/reports/group`,
      {
        params: {
          ...(groupFilterExpanded && {
            start: groupWP.start,
            end: groupWP.end,
            phone: groupWP.phone ? groupWP.phone : "",
          }),
          page: groupWP.pagination.page ? groupWP.pagination.page : 1,
          report_type: 2,
        },
      }
    )
      .then((response) => {
        if (response.status === 201) {
          const {
            data: { data },
          } = response;
          setGroupWP({
            ...groupWP,
            loaded: true,
            reports: [
              ...data.records.map((item) => {
                return {
                  ...item,
                  date: moment(item.send_time)
                    .tz("Europe/Istanbul")
                    .format("LLL"),
                  waiting: item.count - (item.fail + item.success),
                  beforeCredit: item.credit_before,
                };
              }),
            ],
            pagination: {
              ...groupWP.pagination,
              //page: data.page,
              total_page: data.records.length,
            },
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warn(e.response.data.message);
        }
      });
  };
  const custtomWhatsAppIcon = () => {
    return <WhatsApp color="green" />;
  };

  // Grup-tekli sms gönderimleri detaylı bakış
  const getSingleGroupMessageReports = () => {
    Axios.get(
      `${context.api_endpoint}/company/message/reports/group/${groupMessageDetailId}`,
      detailState === 1
        ? {
          params: {
            page: singleGroupSMS.pagination.page ? singleGroupSMS.pagination.page : 1,
            state: "delivered",
            report_type: 1,
          },
        }
        : detailState === 2
          ? {
            params: {
              page: singleGroupSMS.pagination.page ? singleGroupSMS.pagination.page : 1,
              state: "waiting",
              report_type: 1,
            },
          }
          : {
            params: {
              page: singleGroupSMS.pagination.page ? singleGroupSMS.pagination.page : 1,
              state: "undeliverable",
              report_type: 1,
            },
          }
    )
      .then((response) => {
        if (response.status === 201) {
          const {
            data: { data },
          } = response;
          setSingleGroupSMS({
            loaded: true,
            reports: [
              ...data.records.map((item) => {
                return {
                  ...item,
                  message: ReportMessageBody,
                };
              }),
            ],
            pagination: {
              ...singleGroupSMS.pagination,
              //page: data.page,
              total_page: data.records.length,
            },
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warn(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    if (groupMessageDetailId !== '' && tab === 1) {
      getSingleGroupMessageReports()
    }
  }, [singleGroupSMS.pagination.page, detailTab, detailState, groupMessageDetailId])

  // grup-tekli wp gönderim detalı bakış
  const getSingleGroupWPReports = () => {
    Axios.get(
      `${context.api_endpoint}/company/message/reports/group/${groupMessageDetailId}`,
      detailState === 1
        ? {
          params: {
            page: singleGroupWP.pagination.page ? singleGroupWP.pagination.page : 1,
            state: 1,
            report_type: 2,
          },
        }
        : detailState === 2
          ? {
            params: {
              page: singleGroupWP.pagination.page ? singleGroupWP.pagination.page : 1,
              state: "waiting",
              report_type: 2,
            },
          }
          : {
            params: {
              page: singleGroupWP.pagination.page ? singleGroupWP.pagination.page : 1,
              state: 2,
              report_type: 2,
            },
          }
    )
      .then((response) => {
        if (response.status === 201) {
          const {
            data: { data },
          } = response;
          setSingleGroupWP({
            ...singleGroupWP,
            loaded: true,
            reports: [
              ...data.records.map((item) => {
                return {
                  ...item,
                  message: ReportMessageBody,
                };
              }),
            ],
            pagination: {
              ...singleGroupWP.pagination,
              //page: data.page,
              total_page: data.records.length,
            },
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warn(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    console.log(tab)
    if (groupMessageDetailId !== '' && tab === 3) {
      getSingleGroupWPReports()
    }
  }, [singleGroupWP.pagination.page, singleGroupWP.end, singleGroupWP.start, showGroupWPDetail, detailTab])

  const DetailTabMenuItem = ({ btnProps, detailtab }) => {
    return (
      <Button
        {...btnProps}
        iconSize={20}
        style={{ borderRadius: "0px !important" }}
        containerStyle={{ justifyContent: "flex-start" }}
        backgroundColor={
          detailTab === detailtab && detailTab === 0
            ? "green-opacity"
            : detailTab === detailtab && detailTab === 1
              ? "orange-opacity"
              : detailTab === detailtab && detailTab === 2
                ? "red-opacity"
                : ""
        }
        textColor={
          detailTab === detailtab && detailTab === 0
            ? "green"
            : detailTab === detailtab && detailTab === 1
              ? "orange"
              : detailTab === detailtab && detailTab === 2
                ? "red"
                : "grey"
        }
        size="lg"
        fullWidth
      />
    );
  };

  const TabMenuItem = ({ btnProps, Tab }) => {
    return (
      <Button
        {...btnProps}
        iconSize={20}
        iconColor="orange"
        style={{
          borderRadius: "0px !important",
          boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        containerStyle={{ justifyContent: "flex-start" }}
        backgroundColor={tab === Tab ? "primary-opacity" : ""}
        textColor={tab === Tab ? "primary" : "grey"}
        size="lg"
      />
    );
  };
  const TabMenuItem1 = ({ btnProps, Tab }) => {
    return (
      <Button
        {...btnProps}
        iconSize={20}
        iconColor="green"
        style={{
          borderRadius: "0px !important",
          boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        containerStyle={{ justifyContent: "flex-start" }}
        backgroundColor={tab === Tab ? "primary-opacity" : ""}
        textColor={tab === Tab ? "primary" : "grey"}
        size="lg"
      />
    );
  };

  // tekli-grup (manuel) sms detay raporları
  const SingleGroupSMSReports = () => {
    // getSingleGroupMessageReports(state.groupMessageDetailId);
    return (
      <Column className="xs-12 sm-12">
        <Table
          isExist={false}
          headings={{
            phone: {
              label: context.t(`sms.bottom_table_titles.phone`),
              limited_line: 1,
              style: { width: 200 },
            },
            state: {
              label: context.t(`sms.bottom_table_titles.status`),
              style: { width: 300 },
            },
            message: {
              label: context.t(`sms.bottom_table_titles.message_content`),
              style: { width: 800 },
              limited_line: 1,
            },
            _: {
              label: context.t(`sms.bottom_table_titles.details`),
              style: { width: 1 },
            },
          }}
          buttons={[
            {
              icon: "visibility",
              textColor: "green",
              onClick: (row) => {
                getCustomerName(row.phone.slice(2));
                setTimeout(() => {
                  setGroupSmsDetailModal(true);
                  setGroupSmsDetail({
                    ...groupSmsDetail,
                    message: row.message,
                    phone: row.phone,
                  });
                }, 250);
              },
            },
          ]}
          rows={singleGroupSMS.reports}
          loaded={singleGroupSMS.loaded}
          replacements={{
            state: {
              DELIVERED: (
                <RoundedRow color="green">
                  <Check /> {context.t(`sms.replacements.status[0]`)}
                </RoundedRow>
              ),
              UNDELIVERED: (
                <RoundedRow color="red">
                  <Close /> {context.t(`sms.replacements.status[1]`)}
                </RoundedRow>
              ),
              UNDELIVERABLE: (
                <RoundedRow color="red">
                  <SmsFailed /> {context.t(`sms.replacements.status[2]`)}
                </RoundedRow>
              ),
              PAYBACK_COMPLETED: (
                <RoundedRow color="purple">
                  <CardMembership /> {context.t(`sms.replacements.status[3]`)}
                </RoundedRow>
              ),
              EXPIRED: (
                <RoundedRow color="red">
                  <SmsFailed /> {context.t(`sms.replacements.status[5]`)}
                </RoundedRow>
              ),
              "": (
                <RoundedRow color="orange">
                  <Timer /> {context.t(`sms.replacements.status[4]`)}
                </RoundedRow>
              ),
            },
          }}
          pagination={singleGroupSMS.pagination}
        />
      </Column>
    );
  };

  // tekli-grup (menuel) wp detay raporları
  const SingleGroupWPReports = () => {
    return (
      <Column className="xs-12 sm-12">
        <Table
          isExist={false}
          headings={{
            phone: {
              label: context.t(`sms.bottom_table_titles.phone`),
              limited_line: 1,
              style: { width: 200 },
            },
            state: {
              label: context.t(`sms.bottom_table_titles.status`),
              style: { width: 300 },
            },
            message: {
              label: context.t(`sms.bottom_table_titles.message_content`),
              style: { width: 800 },
              limited_line: 1,
            },
            _: {
              label: context.t(`sms.bottom_table_titles.details`),
              style: { width: 1 },
            },
          }}
          buttons={[
            {
              icon: "visibility",
              textColor: "green",
              onClick: (row) => {
                getCustomerName(row.phone.slice(2));
                setTimeout(() => {
                  setGroupSmsDetailModal(true);
                  setGroupSmsDetail({
                    ...groupSmsDetail,
                    message: row.message,
                    phone: row.phone,
                  });
                }, 250);
              },
            },
          ]}
          rows={singleGroupWP.reports}
          loaded={singleGroupWP.loaded}
          replacements={{
            state: {
              DELIVERED: (
                <RoundedRow color="green">
                  <Check /> {context.t(`sms.replacements.status[0]`)}
                </RoundedRow>
              ),
              UNDELIVERED: (
                <RoundedRow color="red">
                  <Close /> {context.t(`sms.replacements.status[1]`)}
                </RoundedRow>
              ),
              UNDELIVERABLE: (
                <RoundedRow color="red">
                  <SmsFailed /> {context.t(`sms.replacements.status[2]`)}
                </RoundedRow>
              ),
              PAYBACK_COMPLETED: (
                <RoundedRow color="purple">
                  <CardMembership /> {context.t(`sms.replacements.status[3]`)}
                </RoundedRow>
              ),
              EXPIRED: (
                <RoundedRow color="red">
                  <SmsFailed /> {context.t(`sms.replacements.status[5]`)}
                </RoundedRow>
              ),
              "": (
                <RoundedRow color="orange">
                  <Timer /> {context.t(`sms.replacements.status[4]`)}
                </RoundedRow>
              ),
            },
          }}
          pagination={singleGroupWP.pagination}
        />
      </Column>
    );
  };

  useEffect(() => {
    getContextSenderSettings();
    getMessageSettings();
    checkWp();
  }, []);

  useEffect(() => {
    if (tab === 3) {
      getGroupWPMessageReports();
    }
  }, [groupWP.pagination.page, tab])

  useEffect(() => {
    console.log("useEffect triggered for reports", { tab, paginationPage: pagination.page });

    if (tab === 0) {
      getMessageReports();
    }

    return () => {
      // Temizleme işlemleri
    };
  }, [tab, pagination.page]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (phone !== '' || phone !== null) {
        setDebouncedValue(phone);
      }
    }, 800);
    return () => {
      clearTimeout(handler);
    };
  }, [phone])


  return (
    <AuthContainer authorities={[1]} limited_permission="sms">
      {!sender && (
        <>
          <UndefinedSenderAlert />
        </>
      )}
      {wpState === false && (
        <>
          <WpUndefinedAlert />
        </>
      )}
      {context.state.company_sms_settings_loaded === false && (
        <Grid>
          <Column className="xs-12 sm-12 mb-2">
            <Alert severity="info">
              <b>{context.t(`sms.alertBoldText`)}</b>
              <br />
              {context.t(`sms.alertText`)}
            </Alert>
          </Column>
        </Grid>
      )}
      <Grid>
        <Column className="xs-12 sm-8 lg-9">
          {/* Tab Menu */}

          {process.env.APP_NAME !== "management" && (
            <TabMenuItem
              tab={0}
              btnProps={{
                iconComponent: MailOutline,
                title: context.t(`sms.singleSMSReports`),
                onClick: () => {
                  setTab(0);
                  setShowGroupSMSDetail(false);
                  setShowGroupWPDetail(false);
                  removeFilter();
                },
              }}
            />
          )}
          {process.env.APP_NAME !== "management" && (
            <TabMenuItem
              tab={1}
              btnProps={{
                iconComponent: MailOutline,
                title: context.t(`sms.groupSMSReports`),
                onClick: () => {
                  setTab(1);
                  setShowGroupWPDetail(false);
                  removeFilter();
                },
              }}
            />
          )}
          <TabMenuItem1
            tab={2}
            btnProps={{
              iconComponent: WhatsApp,
              title: context.t(`sms.singleWPReports`),

              onClick: () => {
                setTab(2);
                setShowGroupSMSDetail(false);
                setShowGroupWPDetail(false);
                removeFilter();
              },
            }}
          />
          <TabMenuItem1
            tab={3}
            btnProps={{
              iconComponent: WhatsApp,
              title: context.t(`sms.groupWPReports`),
              onClick: () => {
                setTab(3);
                setShowGroupSMSDetail(false);
                removeFilter();
              },
            }}
          />
        </Column>

        <Column className="xs-12 sm-4 lg-3">
          <Button
            className="elevation-1"
            iconComponent={CardMembership}
            title={context.t(`sms.creditPaybackButtonTitle`)}
            fullWidth
            onClick={() => creditPayback()}
          />
        </Column>

        <Column className="xs-12 sm-12">
          <Grid className="no-gutters-all">
            <Column className="xs-12 sm-12 md-9 lg-12">
              {/* Tab Area */}
              {tab === 0 && (
                <>
                  {/* Form Control Swicthbox */}
                  <Column className={`xs-12 sm-12`}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={filterExpanded}
                          onChange={(e) => {
                            setPagination((prev) => ({ ...prev, page: 1 }))
                            HandleParams('page', 1)
                            setFilterExpanded(e.target.checked);
                          }}
                        />
                      }
                      label={
                        <FCLabel>
                          <i className="material-icons">filter_list</i>{" "}
                          <span>{context.t(`receipts.filter.title`)}</span>
                        </FCLabel>
                      }
                    />
                  </Column>

                  {/* Filter Area */}
                  {filterExpanded && (
                    <Grid className="mt-2 mb-2">
                      <Column className="xs-12 sm-12 md-12 lg-4">
                        <DatePickerInput
                          className="mb-0"
                          label={context.t(`receipts.filter.startInputLabel`)}
                          value={moment(start).format("YYYY-MM-DD")}
                          onChange={(start) => {
                            setLoaded(false);
                            setPagination((prev) => ({ ...prev, page: 1 }))
                            HandleParams('page', 1)
                            setStart(moment(start).format("YYYY-MM-DD"));
                          }}
                        />
                      </Column>
                      <Column className="xs-12 sm-12 md-12 lg-4">
                        <DatePickerInput
                          className="mb-0"
                          label={context.t(`receipts.filter.endInputLabel`)}
                          value={end}
                          onChange={(end) => {
                            setLoaded(false);
                            setPagination((prev) => ({ ...prev, page: 1 }))
                            HandleParams('page', 1)
                            setEnd(moment(end).format("YYYY-MM-DD"));
                          }}
                        />
                      </Column>
                      <Column className="xs-12 sm-12 md-12 lg-4">
                        <MaskedInput
                          label={context.t(`['customers/add'].phoneInputLabel`)}
                          maskChar={""}
                          mask="999 999 9999"
                          startAdornment="0"
                          value={phone !== null ? phone : ""}
                          placeholder="500 000 0000"
                          onChange={(e) => {
                            setLoaded(false);
                            setPhone(
                              e.target.value.substring(0, 1) === "5" &&
                              e.target.value.replace(/\s/g, "")
                            );
                          }}
                        />
                      </Column>
                    </Grid>
                  )}
                  <Column className="xs-12 sm-12">
                    {loaded ? (
                      <Table
                        refreshAction={() => getMessageReports()}
                        headings={{
                          date: {
                            label: context.t(`sms.headings.date`),
                            limited_line: 1,
                            style: { width: 200 },
                          },
                          phone: {
                            label: context.t(`sms.headings.phone`),
                            style: { maxWidth: 200 },
                          },
                          state: {
                            label: context.t(`sms.headings.status`),
                            style: { width: 200 },
                          },
                          content: {
                            label: context.t(`sms.headings.content`),
                            limited_line: 1,
                            style: { maxWidth: 400 },
                          },
                          _: {
                            label: context.t(`component.actionHeadingText`),
                          },
                        }}
                        buttons={[
                          {
                            icon: "sms",
                            title: context.t(`sms.sendButtonText`),
                            textColor: "green",
                            onClick: (row) => {
                              setShowSms(true);
                              setSms({ content: row.content, date: row.date });
                            },
                          },
                        ]}
                        rows={reports}
                        loaded={loaded}
                        replacements={{
                          state: {
                            DELIVERED: (
                              <RoundedRow color="green">
                                <Check /> {context.t(`sms.replacements.status[0]`)}
                              </RoundedRow>
                            ),
                            UNDELIVERED: (
                              <RoundedRow color="red">
                                <Close /> {context.t(`sms.replacements.status[1]`)}
                              </RoundedRow>
                            ),
                            UNDELIVERABLE: (
                              <RoundedRow color="red">
                                <SmsFailed /> {context.t(`sms.replacements.status[2]`)}
                              </RoundedRow>
                            ),
                            PAYBACK_COMPLETED: (
                              <RoundedRow color="purple">
                                <CardMembership />{" "}
                                {context.t(`sms.replacements.status[3]`)}
                              </RoundedRow>
                            ),
                            EXPIRED: (
                              <RoundedRow color="red">
                                <SmsFailed /> {context.t(`sms.replacements.status[5]`)}
                              </RoundedRow>
                            ),
                            "": (
                              <RoundedRow color="orange">
                                <Timer /> {context.t(`sms.replacements.status[4]`)}
                              </RoundedRow>
                            ),
                          },
                        }}
                        pagination={pagination}
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <img
                          src={
                            process.env.APP_NAME === "salon"
                              ? Loader
                              : process.env.APP_NAME === "en"
                                ? LoaderEN
                                : LoaderSM
                          }
                          width="100"
                          height="100"
                          alt="loading"
                        />
                      </div>
                    )}
                  </Column>
                </>
              )}
              {tab === 1 && (
                <>
                  {/* Form Control Swicthbox */}
                  <Column className={`xs-12 sm-12`}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={groupFilterExpanded}
                          onChange={(e) => {
                            setGroupFilterExpanded(e.target.checked);
                          }}
                        />
                      }
                      label={
                        <FCLabel>
                          <i className="material-icons">filter_list</i>{" "}
                          <span>{context.t(`receipts.filter.title`)}</span>
                        </FCLabel>
                      }
                    />
                  </Column>

                  {/* Filter Area */}
                  {groupFilterExpanded && (
                    <Grid className="mt-2 mb-2">
                      <Column className="xs-12 sm-12 md-12 lg-6">
                        <DatePickerInput
                          className="mb-0"
                          label={context.t(`receipts.filter.startInputLabel`)}
                          value={moment(groupSMS.start).format("YYYY-MM-DD")}
                          onChange={(start) => {
                            setGroupSMS({
                              ...groupSMS,
                              start: moment(start).format("YYYY-MM-DD"),
                            });
                          }}
                        />
                      </Column>
                      <Column className="xs-12 sm-12 md-12 lg-6">
                        <DatePickerInput
                          className="mb-0"
                          label={context.t(`receipts.filter.endInputLabel`)}
                          value={groupSMS.end}
                          onChange={(end) => {
                            setGroupSMS({
                              ...groupSMS,
                              end: moment(end).format("YYYY-MM-DD"),
                            });
                          }}
                        />
                      </Column>
                    </Grid>
                  )}
                  <Column className="xs-12 sm-12">
                    <Table
                      refreshAction={() =>
                        getGroupMessageReports()
                      }
                      headings={{
                        date: {
                          label: context.t(`sms.table_titles.date`),
                          limited_line: 1,
                          style: { width: 200 },
                        },
                        count: {
                          label: context.t(`sms.table_titles.total`),
                          style: { maxWidth: 100 },
                        },
                        success: {
                          label: context.t(`sms.table_titles.successful`),
                          style: { maxWidth: 100 },
                        },
                        fail: {
                          label: context.t(`sms.table_titles.unsuccessful`),
                          style: { maxWidth: 100 },
                        },

                        beforeCredit: {
                          label: context.t(`sms.table_titles.post_postCredit`),
                          style: { maxWidth: 100 },
                        },
                        message: {
                          label: context.t(`sms.table_titles.content`),
                          limited_line: 1,
                          style: { maxWidth: 400 },
                        },
                        _: {
                          label: context.t(`component.actionHeadingText`),
                        },
                      }}
                      buttons={[
                        {
                          icon: "sms",
                          title: context.t(`sms.sendButtonText`),
                          textColor: "green",
                          onClick: (row) => {
                            setDetailTab(0);
                            setDetailState(1);
                            setShowGroupSMSDetail(true);
                            setGroupMessageDetailId(row.id);
                            setReportMessageBody(row.message);
                            setSuccessMessage(row.success);
                            setFailedMessage(row.fail);
                            setWaitingMessage(row.count - (row.success + row.fail));
                            setTimeout(() => {
                              window.scrollTo({
                                top: document.body.scrollHeight,
                                left: 0,
                                behavior: "smooth",
                              });
                            }, 150);
                          },
                        },
                      ]}
                      rows={groupSMS.reports}
                      loaded={groupSMS.loaded}
                      replacements={{
                        state: {
                          DELIVERED: (
                            <RoundedRow color="green">
                              <Check /> {context.t(`sms.replacements.status[0]`)}
                            </RoundedRow>
                          ),
                          UNDELIVERED: (
                            <RoundedRow color="red">
                              <Close /> {context.t(`sms.replacements.status[1]`)}
                            </RoundedRow>
                          ),
                          UNDELIVERABLE: (
                            <RoundedRow color="red">
                              <SmsFailed /> {context.t(`sms.replacements.status[2]`)}
                            </RoundedRow>
                          ),
                          PAYBACK_COMPLETED: (
                            <RoundedRow color="purple">
                              <CardMembership /> {context.t(`sms.replacements.status[3]`)}
                            </RoundedRow>
                          ),
                          "": (
                            <RoundedRow color="orange">
                              <Timer /> {context.t(`sms.replacements.status[4]`)}
                            </RoundedRow>
                          ),
                        },
                      }}
                      pagination={groupSMS.pagination}
                    />
                  </Column>
                </>
              )}
              {tab === 2 && (
                <>
                  <Column className={`xs-12 sm-12`}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={filterExpanded}
                          onChange={(e) => {
                            HandleParams('page', 1)
                            setAutomatedWPReports((prev) => ({ ...prev, pagination: { ...prev.pagination, page: 1 } }))
                            setFilterExpanded(e.target.checked);
                          }}
                        />
                      }
                      label={
                        <FCLabel>
                          <i className="material-icons">filter_list</i>{" "}
                          <span>{context.t(`receipts.filter.title`)}</span>
                        </FCLabel>
                      }
                    />
                  </Column>
                  {/* Filter Area */}
                  {filterExpanded && (
                    <Grid className="mt-2 mb-2">
                      <Column className="xs-12 sm-12 md-12 lg-4">
                        <DatePickerInput
                          className="mb-0"
                          label={context.t(`receipts.filter.startInputLabel`)}
                          value={moment(start).format("YYYY-MM-DD")}
                          onChange={(start) => {
                            setAutomatedWPReports({
                              ...automatedWPReports,
                              loaded: false,
                            });
                            HandleParams('page', 1)
                            setAutomatedWPReports((prev) => ({ ...prev, pagination: { ...prev.pagination, page: 1 } }))
                            setStart(moment(start).format("YYYY-MM-DD"));
                          }}
                        />
                      </Column>
                      <Column className="xs-12 sm-12 md-12 lg-4">
                        <DatePickerInput
                          className="mb-0"
                          label={context.t(`receipts.filter.endInputLabel`)}
                          value={end}
                          onChange={(end) => {
                            setAutomatedWPReports({
                              ...automatedWPReports,
                              loaded: false,
                            });
                            HandleParams('page', 1)
                            setAutomatedWPReports((prev) => ({ ...prev, pagination: { ...prev.pagination, page: 1 } }))
                            setEnd(moment(end).format("YYYY-MM-DD"));
                          }}
                        />
                      </Column>
                      <Column className="xs-12 sm-12 md-12 lg-4">
                        <MaskedInput
                          label={context.t(`['customers/add'].phoneInputLabel`)}
                          maskChar={""}
                          mask="999 999 9999"
                          startAdornment="0"
                          value={phone !== null ? phone : ""}
                          placeholder="500 000 0000"
                          onChange={(e) => {
                            setAutomatedWPReports({
                              ...automatedWPReports,
                              loaded: false,
                            });
                            setPhone(
                              e.target.value.substring(0, 1) === "5" &&
                              e.target.value.replace(/\s/g, "")
                            );
                          }}
                        />
                      </Column>
                    </Grid>
                  )}
                  <Column className="xs-12 sm-12">
                    <Table
                      refreshAction={() =>
                        getAutomatedWPReports()
                      }
                      headings={{
                        date: {
                          label: context.t(`sms.headings.date`),
                          limited_line: 1,
                          style: { width: 200 },
                        },
                        phone: {
                          label: context.t(`sms.headings.phone`),
                          style: { maxWidth: 200 },
                        },
                        state: {
                          label: context.t(`sms.headings.status`),
                          style: { width: 200 },
                        },
                        content: {
                          label: context.t(`sms.headings.content`),
                          limited_line: 1,
                          style: { maxWidth: 400 },
                        },
                        _: {
                          label: context.t(`component.actionHeadingText`),
                        },
                      }}
                      buttons={[
                        {
                          icon: "sms",
                          title: context.t(`sms.sendButtonText`),
                          textColor: "green",
                          onClick: (row) => {
                            setShowSms(true);
                            setSms({ content: row.content, date: row.date });
                          },
                        },
                      ]}
                      rows={automatedWPReports.reports}
                      loaded={automatedWPReports.loaded}
                      replacements={{
                        state: {
                          DELIVERED: (
                            <RoundedRow color="green">
                              <Check /> {context.t(`sms.replacements.status[0]`)}
                            </RoundedRow>
                          ),
                          UNDELIVERED: (
                            <RoundedRow color="red">
                              <Close /> {context.t(`sms.replacements.status[1]`)}
                            </RoundedRow>
                          ),
                          UNDELIVERABLE: (
                            <RoundedRow color="red">
                              <SmsFailed /> {context.t(`sms.replacements.status[2]`)}
                            </RoundedRow>
                          ),
                          PAYBACK_COMPLETED: (
                            <RoundedRow color="purple">
                              <CardMembership /> {context.t(`sms.replacements.status[3]`)}
                            </RoundedRow>
                          ),
                          "": (
                            <RoundedRow color="orange">
                              <Timer /> {context.t(`sms.replacements.status[4]`)}
                            </RoundedRow>
                          ),
                        },
                      }}
                      pagination={automatedWPReports.pagination}
                    />
                  </Column>
                </>
              )}
              {tab === 3 && (
                <>
                  {/* Form Control Swicthbox */}
                  <Column className={`xs-12 sm-12`}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={groupFilterExpanded}
                          onChange={(e) => {
                            setGroupFilterExpanded(e.target.checked);
                          }}
                        />
                      }
                      label={
                        <FCLabel>
                          <i className="material-icons">filter_list</i>{" "}
                          <span>{context.t(`receipts.filter.title`)}</span>
                        </FCLabel>
                      }
                    />
                  </Column>

                  {/* Filter Area */}
                  {groupFilterExpanded && (
                    <Grid className="mt-2 mb-2">
                      <Column className="xs-12 sm-12 md-12 lg-6">
                        <DatePickerInput
                          className="mb-0"
                          label={context.t(`receipts.filter.startInputLabel`)}
                          value={moment(groupWP.start).format("YYYY-MM-DD")}
                          onChange={(start) => {
                            setGroupWP({
                              ...groupWP,
                              start: moment(start).format("YYYY-MM-DD"),
                            });
                          }}
                        />
                      </Column>
                      <Column className="xs-12 sm-12 md-12 lg-6">
                        <DatePickerInput
                          className="mb-0"
                          label={context.t(`receipts.filter.endInputLabel`)}
                          value={groupWP.end}
                          onChange={(end) => {
                            setGroupWP({
                              ...groupWP,
                              end: moment(end).format("YYYY-MM-DD"),
                            });
                          }}
                        />
                      </Column>
                    </Grid>
                  )}
                  <Column className="xs-12 sm-12">
                    <Table
                      refreshAction={() =>
                        getGroupWPMessageReports()
                      }
                      headings={{
                        date: {
                          label: context.t(`sms.table_titles.date`),
                          limited_line: 1,
                          style: { width: 200 },
                        },
                        count: {
                          label: context.t(`sms.table_titles.total`),
                          style: { maxWidth: 100 },
                        },
                        success: {
                          label: context.t(`sms.table_titles.successful`),
                          style: { maxWidth: 100 },
                        },
                        fail: {
                          label: context.t(`sms.table_titles.unsuccessful`),
                          style: { maxWidth: 100 },
                        },

                        beforeCredit: {
                          label: context.t(`sms.table_titles.post_postCredit`),
                          style: { maxWidth: 100 },
                        },
                        message: {
                          label: context.t(`sms.table_titles.content`),
                          limited_line: 1,
                          style: { maxWidth: 400 },
                        },
                        _: {
                          label: context.t(`component.actionHeadingText`),
                        },
                      }}
                      buttons={[
                        {
                          icon: "sms",
                          title: context.t(`sms.sendButtonText`),
                          textColor: "green",
                          onClick: (row) => {
                            setDetailTab(0);
                            setDetailState(1);
                            setShowGroupWPDetail(true);
                            setGroupMessageDetailId(row.id);
                            setReportMessageBody(row.message);
                            setSuccessMessage(row.success);
                            setFailedMessage(row.fail);
                            setWaitingMessage(row.count - (row.success + row.fail));
                            setTimeout(() => {
                              window.scrollTo({
                                top: document.body.scrollHeight,
                                left: 0,
                                behavior: "smooth",
                              });
                            }, 150);
                          },
                        },
                      ]}
                      rows={groupWP.reports}
                      loaded={groupWP.loaded}
                      replacements={{
                        state: {
                          DELIVERED: (
                            <RoundedRow color="green">
                              <Check /> {context.t(`sms.replacements.status[0]`)}
                            </RoundedRow>
                          ),
                          UNDELIVERED: (
                            <RoundedRow color="red">
                              <Close /> {context.t(`sms.replacements.status[1]`)}
                            </RoundedRow>
                          ),
                          UNDELIVERABLE: (
                            <RoundedRow color="red">
                              <SmsFailed /> {context.t(`sms.replacements.status[2]`)}
                            </RoundedRow>
                          ),
                          PAYBACK_COMPLETED: (
                            <RoundedRow color="purple">
                              <CardMembership /> {context.t(`sms.replacements.status[3]`)}
                            </RoundedRow>
                          ),
                          "": (
                            <RoundedRow color="orange">
                              <Timer /> {context.t(`sms.replacements.status[4]`)}
                            </RoundedRow>
                          ),
                        },
                      }}
                      pagination={groupWP.pagination}
                    />
                  </Column>
                </>
              )}
            </Column>
          </Grid>
        </Column>

        {showGroupSMSDetail && (
          <>
            <Column
              className="xs-12 sm-12"
              style={{ border: "1px solid #b2b2b2", margin: "5vh 0" }}
            ></Column>
            <Column className="xs-12 sm-12">
              <Grid className="no-gutters-all">
                {/* Detail Tab Menu */}
                <Column
                  className="xs-12 sm-12 md-3 lg-12"
                  style={{ display: "flex" }}
                >
                  <DetailTabMenuItem
                    detailTab={0}
                    btnProps={{
                      iconComponent: Check,
                      title: `${context.t(
                        `sms.bottom_table_titles.successful`
                      )} ${successMessage} `,
                      onClick: () => {
                        setDetailTab(0);
                        setDetailState(1);
                      },
                    }}
                  />

                  <DetailTabMenuItem
                    detailTab={2}
                    btnProps={{
                      iconComponent: SmsFailed,
                      title: `${context.t(
                        `sms.bottom_table_titles.unsuccessful`
                      )} ${failedMessage}`,
                      onClick: () => {
                        setDetailTab(2);
                        setDetailState(3);
                      },
                    }}
                  />
                </Column>

                {/* Tab Area */}
                <Column className="xs-12 sm-12 md-9 lg-12">
                  <SingleGroupSMSReports />
                </Column>
              </Grid>
            </Column>
          </>
        )}
        {showGroupWPDetail && (
          <>
            <Column
              className="xs-12 sm-12"
              style={{ border: "1px solid #b2b2b2", margin: "5vh 0" }}
            ></Column>
            <Column className="xs-12 sm-12">
              <Grid className="no-gutters-all">
                {/* Detail Tab Menu */}
                <Column
                  className="xs-12 sm-12 md-3 lg-12"
                  style={{ display: "flex" }}
                >
                  <DetailTabMenuItem
                    detailTab={0}
                    btnProps={{
                      iconComponent: Check,
                      title: `${context.t(
                        `sms.bottom_table_titles.successful`
                      )}  ${successMessage}`,
                      onClick: () => {
                        setDetailTab(0);
                        setDetailState(1);
                      },
                    }}
                  />

                  <DetailTabMenuItem
                    detailTab={2}
                    btnProps={{
                      iconComponent: SmsFailed,
                      title: `${context.t(
                        `sms.bottom_table_titles.unsuccessful`
                      )}  ${failedMessage}`,
                      onClick: () => {
                        setDetailTab(2);
                        setDetailState(3);
                      },
                    }}
                  />
                </Column>

                {/* Tab Area */}
                <Column className="xs-12 sm-12 md-9 lg-12">
                  <SingleGroupWPReports />
                </Column>
              </Grid>
            </Column>
          </>
        )}
      </Grid>

      <AlertDialog
        maxWidth="sm"
        fullWidth={true}
        open={showSms}
        closeHandler={() => {
          setShowSms(false);
          setSms({ content: "", date: "" });
        }}
        title={context.t(`sms.dialogTitle`)}
        buttons={[
          {
            title: context.t(`sms.buttonApprove`),
            icon: "close",
            backgroundColor: "primary-opacity",
            textColor: "primary",
          },
        ]}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography className="mt-1 mb-1">{sms.content}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className="mt-1 mb-1 float-right">
              {sms.date}
            </Typography>
          </Grid>
        </Grid>
      </AlertDialog>

      <AlertDialog
        maxWidth="sm"
        open={groupSmsDetailModal}
        closeHandler={() => {
          setGroupSmsDetailModal(false);
        }}
        title={context.t(`sms.bottom_table_titles.details`)}
        buttons={[
          {
            title: context.t(`sms.buttonApprove`),
            icon: "close",
            backgroundColor: "primary-opacity",
            textColor: "primary",
          },
        ]}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography className="mt-1 mb-1 float-right">
              {groupSmsDetail.name}
            </Typography>
            <Typography className="mt-1 mb-1 float-right">&nbsp;</Typography>
            <Typography className="mt-1 mb-1 float-right">
              {groupSmsDetail.surname}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className="mt-1 mb-1 float-right">
              {groupSmsDetail.phone.slice(1)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className="mt-1 mb-2">
              {groupSmsDetail.message}
            </Typography>
          </Grid>
        </Grid>
      </AlertDialog>
    </AuthContainer>
  );
};

export default SMSIndex;
